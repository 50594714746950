import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
    
`;

export const Main = styled.main`
    padding-top: 20px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    @media(min-width: 850px){
        padding-top: 40px;
    }
`;

export const Header = styled.header`
    display: flex;
    width: 100%;
`;

export const SolanoPhoto = styled.img`
    display: none;
    @media(min-width: 850px){
        display: block;
        width: 45%;
    }
`;

export const SolanoPhotoHalf = styled.img`
    width: 50%;
    display: block;
    @media(min-width: 850px) {
        display: none;
    }
`;

export const HeaderRightSide = styled.div`
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;

    @media(min-width: 850px){
        width:55%;
    }

    .container {
        position:relative;
        align-items: stretch;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin: auto;
        max-width: 1230px;
        
        width: 100%;
        max-height: 400px;
        @media (min-width: 850px){
            padding: 10px 30px;
        }
        .success-prev{
            position: absolute;
            top: 35%;
            cursor: pointer;
            z-index: 10;
            left: 0;
            @media (min-width: 850px){
                left: -5px;
            }
        }
        .success-next{
            position: absolute;
            right: 0;
            top: 35%;
            cursor: pointer;
            z-index: 10;
            @media (min-width: 850px){
            }
        }
        .success-slide{
            display: block;
            height: 100%;
            margin-bottom: 50px;
            box-sizing: border-box;
            width: 100%;
            @media (min-width: 1080px){
                margin-bottom: 10px;
            }
            .swiper-wrapper{
                align-items: center;
            }
            .swiper-slide{
                box-sizing: border-box;
                padding: 2px;
                @media(min-width: 850px){
                    padding: 10px;
                }
            }
            .slide-cont{
                align-items: center;
                align-content: center;
                flex-flow: column nowrap;
                display: flex;
                justify-content: flex-start;
                width: 100%;
                height: 100%;

                .quadro-quemsomos{
                    background-color: #fff;
                    padding: 20px 35px 100px; 
                    border-radius: 5px;
                    box-shadow: 0px 0px 16px rgba(0,0,0,0.1);
                }
            }
        }
            
    }
    
        
`;

export const PremiumTitle = styled.h1`
    font-size: 26px;
    font-weight: bold;
    color: #131f30;
`;

export const PremiumSubtitle = styled.h2`
    color: #131f30;
    margin-bottom: 15px;
    text-align: center;
    @media(min-width: 850px){
        margin-bottom: 0;
    }
    
`;

export const Arrow = styled.img`
    width: 35px;
    height: 35px;
`;

export const PremiumBenefits = styled.div`
    display: flex;
`;

export const Benefits = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: -15%;
    z-index: 2;
    @media(min-width: 850px) {
        display: none;

    }
`;

export const BenefitsContent = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 10px 5px;
    width: 100%;
    box-sizing: border-box;

    @media(min-width: 850px) {}
    
`;

export const BenefitsItems = styled.div`
    display: flex;
    justify-content: center;

    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
    border-color: #e6e3e3;

    width: 30%;
    @media(max-width: 850px) {
        width: 20%;
    }
`;

export const BenefitsIcon = styled.img`
    width: 20px;
    margin: 10px 10px;
`;

export const BenefitsText = styled.span``;

export const BenefitsDesktop = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    @media (max-width: 850px) {
        display: none;
    }
`;

export const Theme = styled.h1`
    text-align: center;
    padding: 30px;
`;

export const BestPlan = styled.h1`
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    padding: 30px;
`;

export const PlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    margin-bottom: 20px;
    margin: auto;
    @media (min-width: 1080px){
        margin-bottom: 0;
        flex-direction: row;
        width: 100%;
    }

`;

export const PlansContainer2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 90%;
    margin-bottom: 20px;
    margin: auto;
    @media (min-width: 1080px){
        margin-bottom: 0;
        flex-direction: row;
        width: 100%;
    }

`;


export const Plan = styled.div`
    border: 2px solid #131F30;
    position:relative;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 100%;
    padding-top: 15px;
    @media (min-width: 1080px){
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .space {
        margin-bottom:15px;
    }
`;


export const Top = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;


export const Text = styled.p`
    width: 100%;
    color: ${props => props.color? props.color : "#131F30"};
    text-align: center;
    font-size: ${props => props.size};
    padding-top: 3px;
    margin-bottom: ${props => props.margin};
`;

export const TextFreeContent = styled.p`
    width: 100%;
    color: ${props => props.color? props.color : "#131F30"};
    text-align: left;
    font-size: ${props => props.size};
    padding-top: 3px;
    margin-top: 15px;
`;

export const PlanContent = styled.div`
    border-style: border-box;
    padding: 20px;

`;

export const PremiumButton = styled.button`
    background: none;
    border: none;
    background-color: #131F30;
    border-radius: 15px;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: white;
`;

export const LinkPremiumButton = styled(Link)`
    background: none;
    border: none;
    background-color: #131F30;
    border-radius: 15px;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: white;
    text-align: center;
`;

export const LinkFreeButton = styled(Link)`
    background: none;
    border: none;
    background-color: #131F30;
    border-radius: 15px;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: white;
    text-align: center;
`;

export const LinkSpecialButton = styled(Link)`
    background: none;
    border: none;
    background-color: #ff7e05;
    border-radius: 15px;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: #3b312b;
    text-align: center;
`;
 
export const SuperPremiumButton = styled.button`
    background: none;
    border: none;
    background-color: #ff7e05;
    border-radius: 15px;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: #3b312b;
`;


export const FreeButton = styled.button`
    background: none;
    border: none;
    background-color: #131F30;
    border-radius: 15px;
    margin: 15px 0;
    cursor: pointer;
    width: 95%;
    padding: 14px 20px;
    color: white;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
`;


export const AttentionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 10px;
`;

export const CrossContainer = styled.div`
    display: inline-block;
    position: relative;
`;

export const CrossIcon = styled.img`
    width: 30px;
    height: 30px;
    position: absolute;
    
`;

export const PercentIcon = styled.img`
    position: absolute;
    top: -20px;
    width: 100%;
`;

export const BlackWeekIcon = styled.img`
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0px;
    left: 10px;
    @media (min-width: 1080px){
        width: 100px;
        height: 100px;
        top: 0px;
        left: 30px;
    }
`;

export const PromoIcon = styled.img`
    position: relative;
    width: 80%;
    top: 0px;
    @media (min-width: 1080px){
        width: 80%;
        top: 0px;
    }
`;


export const Esgotado = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 30px;
    padding: 10px 100px;
    
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(30deg);
    z-index: 2;

`;

export const EsgotadoText = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;
    top: 80%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;

`;

export const Curtain = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ccc;
    opacity: 0.6;
`;